<template>
  <div class="page-table only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">
      {{ $t("src.views.apps.musteri.liste.title") }}
      <div class="tanimlamaButon">
        <el-tooltip
          :content="$t('src.views.apps.genel.yeniKaydet')"
          :open-delay="500"
          placement="top"
          v-if="
            yetkiTuru == 1 ||
            (yetkiTuru == 2 && yetkiListesi.includes('YK-musteriC100'))
          "
        >
          <el-button
            v-on:click="routeMusteri('Müşteri Tanımla')"
            size="mini"
            type="text"
            icon="el-icon-plus"
          ></el-button>
        </el-tooltip>

        <el-tooltip
          :content="$t('src.views.apps.genel.refresh')"
          :open-delay="500"
          placement="top"
        >
          <el-button
            v-on:click="refreshPage()"
            style="margin-left: 15px !important"
            type="text"
            icon="el-icon-refresh"
          ></el-button>
        </el-tooltip>
      </div>
    </div>

    <div
      class="page-header card-base card-shadow--medium animated fadeInUp"
      v-loading="loading || stateLoading"
      :element-loading-text="
        loading
          ? $t('src.views.apps.musteri.liste.loading')
          : $t('src.views.apps.musteri.liste.stateLoading')
      "
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-row class="mt-0 mb-10">
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <div>
            <el-radio-group
              size="small"
              v-on:change="handleChange($event)"
              v-model="radio"
              fill="#E5F5F9"
              text-color="gray"
              :disabled="searchLoading"
            >
              <el-radio-button label="1">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #13ce66; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="selectIcon === '1'">{{
                    $t("src.views.apps.musteri.liste.active")
                  }}</label>
                </transition>
              </el-radio-button>
              <el-radio-button label="0">
                <i
                  class="mdi mdi-account mdi-18px"
                  style="color: #ec205f; margin-right: 5px"
                ></i>
                <transition name="slide-fade">
                  <label v-if="selectIcon === '0'">{{
                    $t("src.views.apps.musteri.liste.passive")
                  }}</label>
                </transition>
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <br v-if="!isDesktop" />
        <br v-if="!isDesktop" />
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <el-col :lg="24" :md="24" :sm="24" :xs="24">
            <ul class="islemler" v-if="isDesktop">
              <li
                class="duzenle"
                v-if="
                  yetkiTuru == '1' ||
                  (yetkiTuru == '2' && yetkiListesi.includes('YK-musteriU100'))
                "
              >
                <i class="mdi mdi-circle-edit-outline"></i>
                {{ $t("src.views.apps.musteri.liste.table.editCustomer") }}
              </li>
              <li
                v-if="
                  selectIcon === '1' &&
                  (yetkiTuru == '1' ||
                    (yetkiTuru == '2' &&
                      yetkiListesi.includes('YK-musteriU100')))
                "
                class="sil"
              >
                <i class="mdi mdi-trash-can"></i>
                {{ $t("src.views.apps.musteri.liste.table.setPassive") }}
              </li>
              <li
                v-if="
                  selectIcon === '0' &&
                  (yetkiTuru == '1' ||
                    (yetkiTuru == '2' &&
                      yetkiListesi.includes('YK-musteriU100')))
                "
                class="aktif"
              >
                <i class="mdi mdi-play"></i>
                {{ $t("src.views.apps.musteri.liste.table.setActive") }}
              </li>
            </ul>
            <ul class="islemler2" v-else>
              <li
                class="duzenle"
                v-if="
                  yetkiTuru == '1' ||
                  (yetkiTuru == '2' && yetkiListesi.includes('YK-musteriU100'))
                "
              >
                <i class="mdi mdi-circle-edit-outline"></i>
                {{ $t("src.views.apps.musteri.liste.table.editCustomer") }}
              </li>
              <li v-if="selectIcon === '1'" class="sil">
                <i class="mdi mdi-trash-can"></i>
                {{ $t("src.views.apps.musteri.liste.table.setPassive") }}
              </li>
              <li v-else class="aktif">
                <i class="mdi mdi-play"></i>
                {{ $t("src.views.apps.musteri.liste.table.setActive") }}
              </li>
            </ul>
          </el-col>
        </el-col>
      </el-row>
      <div class="my-custom-table">
        <el-table
          v-loading="searchLoading"
          border
          stripe
          :data="musteriProjeList"
          style="width: 100%"
          class="disTablo"
          :element-loading-text="'Müşteriler Aranıyor. Lütfen Bekleyiniz.'"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 1)"
        >
          <el-table-column
            type="expand"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == 2 && yetkiListesi.includes('YK-musteriR200'))
            "
          >
            <template slot-scope="props">
              <div class="box grow">
                <blockquote
                  :key="index"
                  class="icTablo"
                  v-if="props.row.projeler.length > 0"
                >
                  <el-table
                    :data="props.row.projeler"
                    style="width: 99.5%; padding: 5px; margin: 5px"
                    class="icTablo"
                  >
                    <el-table-column
                      :label="$t('src.views.apps.proje.liste.table.id')"
                      width="auto"
                      style="color: #13ce66"
                    >
                      <template slot-scope="scope">{{
                        scope.row.projeID
                      }}</template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('src.views.apps.proje.liste.table.projeAdi')"
                      width="auto"
                    >
                      <template slot-scope="scope">{{
                        scope.row.projeBaslik
                      }}</template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('src.views.apps.proje.liste.table.ilAdi')"
                      width="auto"
                    >
                      <template slot-scope="scope">{{
                        scope.row.ilBaslik
                      }}</template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('src.views.apps.proje.liste.table.ilceAdi')"
                      width="auto"
                    >
                      <template slot-scope="scope">{{
                        scope.row.ilceBaslik
                      }}</template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('src.views.apps.proje.liste.table.ulkeAdi')"
                      width="auto"
                    >
                      <template slot-scope="scope">{{
                        scope.row.ulkeAdi
                      }}</template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('src.views.apps.proje.liste.table.islem')"
                      width="150"
                      align="right"
                    >
                      <template slot-scope="scope">
                        <el-tooltip
                          content="Müşteri Rapora Git"
                          :open-delay="500"
                          placement="bottom"
                        >
                          <el-button
                            @click="detayPage(scope.row.musteriID, scope.row)"
                            type="text"
                            class="islem-button"
                            style="
                              color: #006cae;
                              margin-left: 0px !important;
                              margin-right: 5px;
                            "
                            icon="mdi mdi-poll mdi-24px"
                          ></el-button>
                        </el-tooltip>
                      </template>
                    </el-table-column>
                  </el-table>
                </blockquote>
              </div>
            </template>
          </el-table-column>

          <el-table-column>
            <template slot="header"> </template>
            <el-table-column
              :label="$t('src.views.apps.genel.sira')"
              type="index"
              :index="indexMethod"
              width="70"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column>
            <template slot="header" slot-scope="props">
              <el-input
                clearable
                v-on:clear="musteriAra"
                :placeholder="
                  $t('src.views.apps.musteri.liste.search.searchName')
                "
                v-model="firmaAdiAra"
                v-debounce:500ms="musteriAra"
                size="mini"
              ></el-input>
            </template>
            <el-table-column prop="musteriAdi" label="Firma Adı" width="180">
              <template slot-scope="props">
                <div class="new-line-text">
                  {{ props.row.musteriAdi }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column>
            <template slot="header" slot-scope="props">
              <el-input
                clearable
                v-on:clear="musteriAra"
                :placeholder="
                  $t('src.views.apps.musteri.liste.search.searchUnvan')
                "
                v-model="unvanAra"
                v-debounce:500ms="musteriAra"
                size="mini"
              ></el-input>
            </template>
            <el-table-column
              prop="unvan"
              :label="$t('src.views.apps.musteri.liste.table.unvan')"
              :width="isDesktop ? 'auto' : '300'"
            >
              <template slot-scope="props">
                <div class="new-line-text">
                  {{ props.row.unvan }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column>
            <template slot="header" slot-scope="props">
              <el-input
                clearable
                v-on:clear="musteriAra"
                :placeholder="
                  $t('src.views.apps.musteri.liste.search.searchAddress')
                "
                v-model="adresAra"
                v-debounce:500ms="musteriAra"
                size="mini"
              ></el-input>
            </template>
            <el-table-column
              prop="adres"
              :label="$t('src.views.apps.musteri.liste.table.adres')"
              width="370"
            >
              <template slot-scope="props">
                <div class="new-line-text">
                  {{ props.row.adres }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column>
            <template slot="header" slot-scope="props">
              <el-input
                clearable
                v-on:clear="musteriAra"
                :placeholder="
                  $t('src.views.apps.musteri.liste.search.searchTelephone')
                "
                v-model="telefonAra"
                v-debounce:500ms="musteriAra"
                size="mini"
              ></el-input>
            </template>
            <el-table-column
              prop="telefon"
              :label="$t('src.views.apps.musteri.liste.table.telephone')"
              width="200"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column>
            <template slot="header" slot-scope="props">
              <el-input
                clearable
                v-on:clear="musteriAra"
                :placeholder="
                  $t('src.views.apps.musteri.liste.search.searchEmail')
                "
                v-model="epostaAra"
                v-debounce:500ms="musteriAra"
                size="mini"
              ></el-input>
            </template>
            <el-table-column
              prop="eposta"
              :label="$t('src.views.apps.musteri.liste.table.email')"
              width="280"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column
            fixed="right"
            v-if="
              yetkiTuru == '1' ||
              (yetkiTuru == '2' && yetkiListesi.includes('YK-musteriU100'))
            "
          >
            <el-table-column
              :label="$t('src.views.apps.genel.islem')"
              align="right"
              :width="isDesktop ? '100' : '100'"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="
                    $t('src.views.apps.musteri.liste.table.editCustomer')
                  "
                  :open-delay="500"
                  v-if="
                    yetkiTuru == '1' ||
                    (yetkiTuru == '2' &&
                      yetkiListesi.includes('YK-musteriU100'))
                  "
                  placement="bottom"
                >
                  <el-button
                    @click="updatePage(scope.row)"
                    type="text"
                    class="islem-button"
                    style="color: #f7ba2a; margin-left: 0px !important"
                    icon="mdi mdi-circle-edit-outline mdi-24px"
                  >
                  </el-button>
                </el-tooltip>

                <el-tooltip
                  :content="$t('src.views.apps.musteri.liste.table.setPassive')"
                  :open-delay="500"
                  v-if="selectIcon === '1'"
                  placement="bottom"
                >
                  <el-button
                    @click="changeMusteriDurum(scope.row, '0')"
                    class="buttonDel"
                    style="margin-left: 15px !important"
                    type="text"
                    icon="mdi mdi-trash-can mdi-24px"
                  ></el-button>
                </el-tooltip>

                <el-tooltip
                  :content="$t('src.views.apps.musteri.liste.table.setActive')"
                  :open-delay="500"
                  v-if="selectIcon === '0'"
                  placement="bottom"
                >
                  <el-button
                    @click="changeMusteriDurum(scope.row, '1')"
                    type="text"
                    style="margin-left: 15px !important"
                    class="islem-button-play islem-button ml-15"
                    icon="mdi mdi-play mdi-24px"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div
        v-if="!musteriProjeList"
        style="
          width: 100%;
          height: 50px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        {{ $t("src.views.apps.messages.noData") }}
      </div>
      <el-pagination
        background
        layout="sizes,prev, pager, next, jumper, total"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="pageChange"
        :page-sizes="[20, 50, 100]"
        :current-page.sync="page"
        :next-click.sync="page"
        :prev-click.sync="page"
        @next-click="pageChange"
        @prev-click="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import musteriService from "../../../WSProvider/MusteriService";
import EventBus from "@/components/event-bus";
import notification from "../../../notification";
import functions from "../../../functions";
import jwt_decode from "jwt-decode";
import MusteriRapor from "../rapor/MusteriRapor.vue";

var moment = require("moment");

export default {
  name: "MusteriListesi",
  components: { MusteriRapor },
  data() {
    return {
      isDesktop: false,
      loading: false,
      stateLoading: false,
      searchLoading: false,

      //editDialogLoading: false,
      editDialog: false,
      editDialogData: {},

      musteriList: [],
      musteriProjeList: [],
      yetkiListesi: [],
      tarihler: [],
      yetkiTuru: "",
      yoneticiID: "",
      radio: "1",
      musteriForm: {
        musteriAdi: "",
        eposta: "",
        firmaAdi: "",
        unvan: "",
        telefon: "",
      },
      rulesMusteri: {},

      sizes: 20,
      selectIcon: "1",
      page: 0,
      postPage: 0,
      total: 4,
      firmaAdiAra: "",
      unvanAra: "",
      adresAra: "",
      telefonAra: "",
      epostaAra: "",
    };
  },
  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      this.yoneticiID = this.$store.getters.getYoneticiID;
      //console.log(this.yetkiListesi)
    }
    EventBus.$on("musterilistesi", () => {
      this.refreshPage();
    });
  },
  mounted() {
    //this.getMusteriListesi();
    this.getMusteriProjeListesi();
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    checkDevice() {
      this.isDesktop = window.innerWidth >= 768;
    },

    updatePage(data) {
      this.$store.commit("changeMusteriUpdateData", data.musteriID);
      functions.routeSayfa("Müşteri Güncelle", this);
    },

    detayPage(musteriID, proje) {
      // console.log(musteriID)
      // console.log(proje)
      if (
        this.yetkiTuru == 1 ||
        (this.yetkiTuru == 2 && this.yetkiListesi.includes("YK-musteriP100"))
      ) {
        this.$store.commit("changeMusteriDetayData", musteriID);
        this.$store.commit("changeProjeDetayData", proje);
        functions.routeSayfa("Müşteri Rapor", this);
        //console.log("this")
        //console.log(this)}
      }
    },
    musteriAra() {
      this.postPage = 0;
      this.page = 1;
      this.dateVisible = false;
      //siparisNo, adSoyad, telefon, country, city, state, baslangicTarihi, bitisTarihi, durum, baslangic, limit,
      if (
        this.firmaAdiAra.length > 0 ||
        this.unvanAra.length > 0 ||
        this.adresAra.length > 0 ||
        this.telefonAra.length > 0 ||
        this.epostaAra.length > 0
      ) {
        try {
          this.searchLoading = true;
          //kullaniciAdi, adSoyad, mail, telefon, durum, baslangic, limit)
          musteriService
            .musteriFilter(
              this.firmaAdiAra,
              this.unvanAra,
              this.adresAra,
              this.telefonAra,
              this.epostaAra,
              this.selectIcon,
              this.postPage,
              this.sizes
            )
            .then((response) => {
              if (response.status == 200) {
                this.musteriProjeList = response.data;
                this.total = response.count;
              }
              localStorage.setItem("userDataBGSurec", response.token);
              this.searchLoading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.musteriProjeList = [];
                  this.total = 0;
                }
              } else {
                //notification.Status(503, this)
              }
              this.searchLoading = false;
            });
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false;
        }
      } else {
        //this.getMusteriListesi();
        this.getMusteriProjeListesi();
        // this.getMusteriSayisi();
      }
    },

    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;
      this.firmaAdiAra = "";
      this.unvanAra = "";
      this.adresAra = "";
      this.telefonAra = "";
      this.epostaAra = "";
      // this.getMusteriSayisi();
      //this.getMusteriListesi();
      this.getMusteriProjeListesi();
    },

    musteriSifreGuncelle(musteriID) {
      this.$confirm(
        this.$t("src.views.apps.musteri.messages.sendMessage"),
        this.$t("src.views.apps.genel.uyari"),
        {
          confirmButtonText: this.$t("src.views.apps.genel.yes"),
          cancelButtonText: this.$t("src.views.apps.genel.no"),
          type: "warning",
        }
      ).then(() => {
        try {
          notification.Status(
            "warning",
            this,
            "The message is being delivered. Please wait."
          );
          musteriService
            .musteriSifreGuncelle(musteriID)
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              notification.Status(
                "success",
                this,
                "Message Sent Successfully."
              );
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
            });
        } catch (e) {
          // notification.Status("danger", this, "Failed to send password to client. An error occurred during the operation.")
        }
      });
    },

    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },

    startUpdate() {
      this.musteriForm.firmaAdi = this.editDialogData.firmaAdi;
      this.musteriForm.unvan = this.editDialogData.unvan;
      this.musteriForm.eposta = this.editDialogData.mail;
      this.musteriForm.telefon = this.editDialogData.telefon;
      // this.musteriForm.dogumTarihi = moment(this.editDialogData.dogumTarihi).format("DD-MM-YYYY");
    },

    changeMusteriDurum(selection, durum) {
      this.$confirm(
        "Müşterinin durumunu güncellemek istediğinizden emin misiniz?",
        this.$t("src.views.apps.genel.uyari"),
        {
          confirmButtonText: this.$t("src.views.apps.genel.yes"),
          cancelButtonText: this.$t("src.views.apps.genel.no"),
          type: "warning",
        }
      ).then(() => {
        try {
          this.stateLoading = true;
          musteriService
            .musteriDurumDegistir(selection.musteriID, durum)
            .then((response) => {
              if (response.status == 200) {
                // this.getMusteriListesi();
                this.getMusteriProjeListesi();
              }
              this.stateLoading = false;
              notification.Status("success", this, response.msg);
              localStorage.setItem("userDataBGSurec", response.token);
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.stateLoading = false;
            });
        } catch (e) {
          // notification.Status("success", this, "An error occurred while fetching the customer number.");
          this.stateLoading = false;
        }
      });
    },

    getMusteriSayisi() {
      try {
        musteriService
          .musteriSayisi(this.selectIcon)
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("userDataBGSurec", response.token);
            }
            this.total = parseInt(response.data);
            // console.log("bbbbbb")
            //console.log(this.total)
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.total = 0;
              }
            } else {
              //notification.Status(503, this)
            }
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer number.")
      }
    },

    getMusteriListesi() {
      try {
        this.loading = true;
        musteriService
          .musteriListesi(this.selectIcon, this.postPage, this.sizes)
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.total = parseInt(response.data.musteriSayisi);
              this.musteriList = response.data;
              this.total = parseInt(response.count);
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.musteriList = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },

    siralaYetkiKodlari(yetkiListesi) {
      const yetkiKodlari = yetkiListesi;
      const siraliYetkiKodlari = yetkiKodlari.sort();

      return siraliYetkiKodlari;
    },

    siralaYetkiKodlari2(yetkiListesi) {
      const yetkiKodlari = yetkiListesi.map((item) => item.yetkiKodu);
      const siraliYetkiKodlari = yetkiKodlari.sort((a, b) =>
        a.localeCompare(b)
      );
      return siraliYetkiKodlari;
    },

    karsilastirYetkiKodlari(yetkiKodlari1, yetkiKodlari2) {
      if (yetkiKodlari1.length !== yetkiKodlari2.length) {
        return false;
      }

      for (let i = 0; i < yetkiKodlari1.length; i++) {
        if (yetkiKodlari1[i] !== yetkiKodlari2[i]) {
          return false;
        }
      }

      return true;
    },

    getMusteriProjeListesi() {
      const logInYetkiler = this.$store.state.yetkiListesi;
      try {
        this.loading = true;
        musteriService
          .musterilerinProjeleri(
            this.selectIcon,
            this.postPage,
            this.yetkiTuru == 1 ? this.sizes : 20,
            1
          )
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);

            if (logInYetkiler != 1) {
              const siraliYetkiler = this.siralaYetkiKodlari(logInYetkiler);
              const siraliYoneticiYetkileri = this.siralaYetkiKodlari2(
                response.yoneticiYetkileri
              );

              const eslesmeVar = this.karsilastirYetkiKodlari(
                siraliYetkiler,
                siraliYoneticiYetkileri
              );

              if (!eslesmeVar) {
                notification.Status(
                  "warning",
                  this,
                  "Yetkilerinizde değişiklik yapılmıştır. Lütfen tekrar giriş yapınız."
                );
                this.$store.commit("setLogout");
                this.$router.push("/login");
                localStorage.removeItem("userDataBGSurec");
              }
            }

            if (response.status == 200) {
              this.musteriList = response.data;
              if (this.yetkiTuru == 1) {
                this.musteriProjeList = response.data;
                this.total = parseInt(response.count);
              }
              if (this.yetkiTuru == 2) {
                this.musteriProjeList = response.data.filter((musteri) => {
                  return musteri.atanmislar.some((yonetici) => {
                    return yonetici.yoneticiID === this.yoneticiID;
                  });
                });
                this.total = parseInt(this.musteriProjeList.length);
              }

              //this.total = parseInt(response.count);
              //this.musteriProjeList = response.data;
              this.tarihler = functions.daysInMonths();

              if (this.musteriProjeList.length > 0) {
                this.musteriProjeList.forEach((musteri) => {
                  if (musteri.projeler.length > 0) {
                    musteri.projeler.forEach((proje) => {
                      proje.toplamProjeSuresi = 0;
                      proje.baslamaGecenSure = 0;
                      proje.baslamaSureYuzde = 0;
                      proje.kalanSure = 0;

                      proje.toplamProjeSuresi = getDays(
                        proje.baslangicTarihi,
                        proje.bitisTarihi
                      );
                      projeBasladiMi(
                        moment().format("YYYY-MM-DD"),
                        proje.baslangicTarihi
                      )
                        ? (proje.baslamaGecenSure = getDays(
                            proje.baslangicTarihi,
                            moment().format("YYYY-MM-DD")
                          ))
                        : (proje.baslamaGecenSure = 0);
                      proje.kalanSure =
                        proje.toplamProjeSuresi - proje.baslamaGecenSure;
                      if (proje.kalanSure < 0) {
                        proje.kalanSure = 0;
                      }

                      proje.baslamaSureYuzde = parseFloat(
                        (parseInt(proje.baslamaGecenSure) * 100) /
                          parseInt(proje.toplamProjeSuresi)
                      ).toFixed(1);
                      if (proje.baslamaSureYuzde > 100) {
                        proje.baslamaSureYuzde = 100;
                      }

                      proje.toplamSozlesme = 0;
                      proje.bitenSozlesme = 0;
                      // burada projede bulunan sozlesmeleri oranlarını yuzdelik değerlere ceviriyoruz
                      // yuzde deger = sozlesmeYuzde * 100 / toplamSozlesmelerYuzdeDegerleriToplamlari
                      // 2 foreach donmesinin sebebi once projedeki sözleşmelerin yuzdelerinin toplamlarına ihtiyacımız olmasından! 2.foreach yuzdelik orana cevirmek için.
                      proje.sozlesmeler.forEach((sozlesme) => {
                        proje.toplamSozlesme += parseFloat(sozlesme.yuzdeDeger);
                        proje.bitenSozlesme += sozlesmeBittiMi(
                          moment().format("YYYY-MM-DD"),
                          sozlesme.isTeslimTarihi
                        )
                          ? parseFloat(sozlesme.yuzdeDeger)
                          : 0;
                      });
                      proje.bitmeYuzdesi = parseFloat(
                        (proje.bitenSozlesme * 100) / proje.toplamSozlesme
                      ).toFixed(1);

                      proje.sozlesmeler.forEach((s) => {
                        s.toplamYuzde = parseFloat(
                          (s.yuzdeDeger * 100) / proje.toplamSozlesme
                        ).toFixed(1);

                        // sozlesmenin başlangıc ve bitiş tarihleri arasında hangi aylar var ve o aylarda kac gun is yapılmıs(yapılacak) objesini donduren fonksiyon
                        // bu kodlar sozlesmeleri hangi tarihlerde yani hangi aylarda yuzde kac iş yapılması gerektiğini temsil ediyor. O yüzden ay ay kac gun calısıldıgı yuzdeliğe ceviriliyor (o ay calısılan gun * 100 / aylarda calısılan günlerin toplamı)
                        s.toplamCalisilanGun = 0;
                        s.sozlesmeAylari = getMonthsBetween(
                          s.baslamaTarihi,
                          s.isTeslimTarihi
                        );

                        //console.log(s.sozlesmeBaslik)
                        //console.log(s.sozlesmeAylari)
                        s.sozlesmeAylari.forEach((ay) => {
                          //console.log(proje.projeBaslik)
                          //console.log(s)
                          s.toplamCalisilanGun += parseInt(ay.days);
                          //console.log("ay.day - > " + ay.days )
                        });
                        //console.log(s.toplamCalisilanGun)
                        s.sozlesmeAylari.forEach((ay, index) => {
                          //console.log("index -> " + index)
                          // eger tek bir ay içinde yapılcaksa sozlesme o zaman sozlesme gunlerini ayda bulunan günlere göre yuzdelik orana cevirdik
                          // birden fazla ise aralık yani sözleşmek ocak subat mart aylarında geçerli ise örneğin, o zaman ocak ayı için yuzdelik hesap toplam is gunune gore hesaplandı. ocak ayındaki gun * 100 / toplam iş gunu şeklinde
                          var daysInMonth = this.tarihler.find(
                            (e) => e.monthIndex == ay.monthIndex
                          );
                          // console.log(this.tarihler.find(e => e.monthIndex == ay.monthIndex))
                          // console.log("Proje Adı " + proje.projeBaslik)
                          //console.log("Sözleşme Adı " + s.sozlesmeBaslik)
                          //console.log("daysInMonth.day " + daysInMonth.days)

                          ay.dayPercentage =
                            s.sozlesmeAylari.length == 1
                              ? parseFloat(
                                  (parseInt(ay.days) * 100) / daysInMonth.days
                                ).toFixed(1)
                              : index > 0 && index < s.sozlesmeAylari.length - 1
                              ? 100
                              : parseFloat(
                                  (parseInt(ay.days) * 100) / daysInMonth.days
                                ).toFixed(1);

                          //console.log("s.sozlesmeAylari.length " + s.sozlesmeAylari.length)
                          //console.log("ay.days "+ay.days)
                          //console.log("daysInMonth.days "+daysInMonth.days)
                          //console.log("s.toplamCalisilanGun "+s.toplamCalisilanGun)
                        });
                        // ---------------------------------------------------------------------------------------

                        // Bugunun tarihi aralık olarak secilen gün ile karsılaştırılıyor ve bulunulan gün başlama tarihinden önce ise bitti = 0, tarihlerin arasında ise bitti = 1, bitiş tarihini geçmiş ise bitti = 2 (bitti değişkeni o sözleşmenin durumunu temsil ediyor.)
                        s.bitti = checkDateRange(
                          s.baslamaTarihi,
                          s.isTeslimTarihi
                        ); // durum = 0 başlamadı, durum = 1 başlandı, durum = 2 bitti
                        //console.log("başlama" + s.baslamaTarihi)
                        //   console.log("bitme" + s.isTeslimTarihi)
                      });
                    });
                  }
                });
              }
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.musteriProjeList = [];
                this.total = 0;
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },

    routeMusteri(name, scope) {
      if (scope) {
        this.$store.commit("changeMusteriUpdateData", scope);
      }
      functions.routeSayfa(name, this);
    },

    indexMethod(index) {
      return (this.page - 1) * this.sizes + index + 1;
    },

    handleSizeChange(val) {
      this.sizes = val;
      //this.getMusteriListesi();
      this.getMusteriProjeListesi();
    },

    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      //this.getMusteriListesi();
      this.getMusteriProjeListesi();
      document
        .querySelector(".scrollable")
        .scrollTo({ top: 0, behavior: "smooth" });
      this.firmaAdiAra = "";
      this.unvanAra = "";
      this.adresAra = "";
      this.telefonAra = "";
      this.epostaAra = "";
    },

    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      //this.getMusteriListesi();
      this.getMusteriProjeListesi();
    },
  },
};

function getDays(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür ve gün farkını hesapla
  const tarih1Moment = moment(tarih1);
  const tarih2Moment = moment(tarih2);
  const gunFarki = tarih2Moment.diff(tarih1Moment, "days");

  // Sonucu döndür
  return gunFarki;
}

function sozlesmeBittiMi(tarih1, tarih2) {
  // Tarihleri Moment objelerine dönüştür
  const momentTarih1 = moment(tarih1);
  const momentTarih2 = moment(tarih2);

  // Tarih1 tarih2'den sonra mı?
  return momentTarih1.isAfter(momentTarih2);
}

function projeBasladiMi(bugun, baslangicTarihi) {
  const momentBugun = moment(bugun);
  const momentBaslangic = moment(baslangicTarihi);

  return momentBugun.isAfter(momentBaslangic);
}

function getMonthsBetween(start, end) {
  moment.locale("tr");
  const results = [];

  for (
    let m = moment(start);
    m.isSameOrBefore(end, "month");
    m.add(1, "months")
  ) {
    //for (let m = moment(start); m.isBefore(end); m.add(1, 'months')) {
    const monthStart = moment(m).startOf("month");
    const monthEnd = moment(m).endOf("month");

    let daysInMonth = monthEnd.diff(monthStart, "days") + 1; //ayın günlerinikaç gün olduğunu hesaplıyor

    if (m.isSame(moment(start).startOf("month"), "month")) {
      daysInMonth -= moment(start).date();
    }
    if (m.isSame(moment(end).endOf("month"), "month")) {
      daysInMonth -= monthEnd.date() - moment(end).date();
    }

    results.push({
      month: monthStart.format("MMMM"),
      monthIndex: parseInt(monthStart.month()) + 1,
      days: daysInMonth, //- 1
    });
  }
  return results;
}

function checkDateRange(start, end) {
  const today = moment();
  if (today.isBefore(start, "day")) {
    return 0;
  } else if (today.isBetween(start, end, "day")) {
    return 1;
  } else {
    return 2;
  }
}
</script>

<style lang="scss">
.el-table .info-row {
  background: rgba(125, 199, 225, 0.45);
}

.el-table .error-row {
  background: rgba(249, 0, 26, 0.08);
}

.el-table .success-row {
  background: rgba(3, 249, 0, 0.08);
}

.el-table .warning-row {
  background: rgba(245, 249, 0, 0.08);
}
</style>
<style lang="scss" scoped>
.refresh {
  color: #fff;
  float: right;
  padding: 0 !important;
  transition: all 0.5s;
}

.refresh:hover {
  transform: rotate(360deg);
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}

.kaldirilmis {
  background: #ec205f;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}
</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 0 10px !important;
    font-size: 11px;
    border-radius: 0;
  }
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
<style>
/*
.icTablo{
    background-color: rgb(59, 123, 179); /*rgb(148, 197, 240);
}*/

.icTablo th.el-table__cell > .cell {
  background-color: aliceblue;
}

.islem-button {
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 0;
  line-height: 1; /* Butonun içeriği ile yükseklik arasındaki mesafeyi sıfırlar */
}

.buttonDel {
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 0;
  line-height: 1; /* Butonun içeriği ile yükseklik arasındaki mesafeyi sıfırlar */
}

.icTablo .el-table .cell {
  line-height: 30px !important;
}

.el-table thead {
  line-height: 30px !important;
}
</style>
